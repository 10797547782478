import React, { useEffect } from "react";
import Container from "./components/layout/Container";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { API_GRAPHQL_URL, COOKIES } from "./shared/Config";
import ReactGA from "react-ga";
import Routes from "src/routes/Routes";
import StyleGuide from "src/components/styleguide/StyleGuide";
// import ErrorBoundary from "src/shared/errorpages/ErrorBoundary";
import ChartUtil from "src/utils/ChartUtil";
// import { init as SentryInit } from "@sentry/browser";
import GraphQLUtil from "src/utils/GraphQLUtil";
import DirectConnectAndCreditToast from "src/components/company/DirectConnectAndCreditToast";
import Login from "src/components/auth/Login";
import ForgotPassword from "src/components/auth/ForgotPassword";
import ResetPassword from "src/components/auth/ResetPassword";
import { GET_USER_DETAILS } from "src/graphql/auth/queries";
import { setUserDetails } from "src/redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { loadCookie, removeCookies } from "src/shared/SessionHelper";
import { NO_CACHE } from "src/shared/Constants";
import Enterprise from "src/components/enterprise/index.jsx";
import { GET_MY_PROFILE } from "./graphql/users/queries";
import { setAcceptTerms } from "./redux/userSlice";
import BlockUi from "react-block-ui";
import { Route, BrowserRouter, Redirect } from "react-router-dom";
import GhostUserLogin from "./components/auth/GhostUserLogin";
import { ENTERPRISE_USER_INFO } from "./graphql/enterprise/queries";
import * as Sentry from "@sentry/react";
import { addToast } from "./utils/ToastUtil";
import { dismissToast } from "./redux/toastSlice";
import ServiceFusionLogin from "src/components/auth/ServiceFusionLogin";
import { QUERY_GET_COMPANY_SETTINGS } from "./graphql/campaigns/postcard/queries";
import { setCookie, extractIdFromUrl } from "./shared/SessionHelper";
import { REVIEW_SOURCE } from "src/shared/Constants";
import Partner from "./components/partner";
import { PARTNER_USER_INFO } from "./graphql/partner/queries";
import "./App.scss";

// init apollo client
const client = new ApolloClient({
  uri: API_GRAPHQL_URL,
  request: (operation) => {
    operation.setContext({
      headers: {
        AUTHORIZATION: loadCookie(COOKIES.AUTH_TOKEN),
      },
    });
  },
  onError: (err) => {
    if (GraphQLUtil.getError(err) === "unauthorized") {
      removeCookies();
      let url = "company";
      if (window.location.pathname.includes("enterprise")) {
        url = "enterprise";
      } else if (window.location.pathname.includes("partner")) {
        url = "partner";
      }
      window.location = `${window.location.origin}/${url}/login`;
    }
  },
});

// init sentry
// if (process.env.REACT_APP_ENV !== "development") {
// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   environment: process.env.REACT_APP_ENV,
//   // integrations: [
//   //   Sentry.reactRouterV5BrowserTracingIntegration({ history, matchPath }),
//   // ],
//   tracesSampleRate: 1.0,
// });
// SentryInit({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   environment: process.env.REACT_APP_ENV,
//   ignoreErrors: ["ResizeObserver loop limit exceeded"],
// });
// }

// init highchart configs
ChartUtil.init();

const App = () => {
  const getCompanySettings = async (companyId) => {
    const queryParams = {
      query: QUERY_GET_COMPANY_SETTINGS,
      variables: {
        companyId,
      },
      fetchPolicy: NO_CACHE,
    };

    try {
      const { data } = await client.query(queryParams);
      if (data?.companySettings?.reviewPlatform === REVIEW_SOURCE.PULSE_M) {
        setCookie(COOKIES.IS_PULSEM, true, process.env.REACT_APP_APP_DOMAIN);
      }
    } catch (e) {
      console.error("CREDIT SUMMARY ERROR: ", e);
    }
  };

  const getPartnerProfile = async (partnerUserId, companyId) => {
    const queryParams = {
      query: PARTNER_USER_INFO,
      variables: {
        partnerUserId,
        companyId,
      },
      fetchPolicy: NO_CACHE,
    };

    try {
      const { data } = await client.query(queryParams);
      return data?.getPartnerUser;
    } catch (e) {
      removeCookies();
      console.error("CREDIT SUMMARY ERROR: ", e);
    }
  };
  const getEnterpriseProfile = async (id) => {
    const queryParams = {
      query: ENTERPRISE_USER_INFO,
      variables: {
        id,
      },
      fetchPolicy: NO_CACHE,
    };

    try {
      const { data } = await client.query(queryParams);
      return data?.getEnterpriseUser;
    } catch (e) {
      removeCookies();
      console.error("CREDIT SUMMARY ERROR: ", e);
    }
  };

  const getProfile = async (companyUserId) => {
    const queryParams = {
      query: GET_MY_PROFILE,
      variables: {
        companyUserId,
      },
      fetchPolicy: NO_CACHE,
    };

    try {
      const { data } = await client.query(queryParams);
      dispatch(setAcceptTerms(data?.getCompanyUser?.acceptsTerms));
      return data?.getCompanyUser;
    } catch (e) {
      removeCookies();
      console.error("CREDIT SUMMARY ERROR: ", e);
    }
  };
  const fetchUserInfo = async (token) => {
    const queryParams = {
      query: GET_USER_DETAILS,
      variables: {
        token,
      },
      fetchPolicy: NO_CACHE,
    };

    try {
      const { data } = await client.query(queryParams);
      if (data?.getAuthentication?.authenticated) {
        const { getAuthentication } = data;
        // extract the companyId from url
        const companyId = extractIdFromUrl();
        if (companyId) {
          await getCompanySettings(companyId);
        }

        if (getAuthentication?.partnerUserId) {
          dispatch(setAcceptTerms(true));
          const response = getPartnerProfile(
            getAuthentication?.partnerUserId,
            companyId,
          );
          dispatch(
            setUserDetails({ ...getAuthentication, ...response, companyId }),
          );
        } else if (getAuthentication?.enterpriseUserId) {
          dispatch(setAcceptTerms(true));
          const response = await getEnterpriseProfile(
            getAuthentication?.enterpriseUserId,
          );

          dispatch(
            setUserDetails({ ...getAuthentication, ...response, companyId }),
          );
        } else {
          const response = await getProfile(getAuthentication?.companyUserId);
          dispatch(setUserDetails({ ...getAuthentication, ...response }));
        }

        if (
          getAuthentication?.expired &&
          !window.location.pathname.includes("update-expired-password")
        ) {
          let url = "company";
          if (window.location.pathname.includes("enterprise")) {
            url = "enterprise";
          }
          window.location = `${window.location.origin}/${url}/update-expired-password`;
        }
      }
    } catch (e) {
      removeCookies();
      console.error("CREDIT SUMMARY ERROR: ", e);
    }
  };
  const dispatch = useDispatch();

  const { loading, isAuthenticated } = useSelector((state) => state.user);
  const toastData = useSelector((state) => state.toast);

  // effects
  React.useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.initialize(process.env.REACT_APP_GTAG_SRC);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    const authToken = loadCookie(COOKIES.AUTH_TOKEN);
    if (authToken) {
      fetchUserInfo(authToken);
    }
  }, []);

  useEffect(() => {
    if (toastData.showToast) {
      const { message, type } = toastData;
      addToast({
        message,
        type,
        onClose: () => {
          dispatch(dismissToast());
        },
      });
    }
  }, [toastData.showToast]);

  if (isAuthenticated && loading) {
    return (
      <div className="page-loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  // render
  return (
    <BlockUi blocking={isAuthenticated && loading}>
      <Sentry.ErrorBoundary
        fallback={() => (
          <div className="text-center jumbotron">
            <div>
              <h3>
                <i className="fa fa-warning text-orange"></i> Oops! An error
                occurred.
              </h3>
            </div>
          </div>
        )}
      >
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Route exact path="/">
              <Redirect to="/company/login" />
            </Route>
            <Routes
              routes={[
                {
                  id: 0,
                  path: "/companies/*",
                  render: () => (
                    <React.Fragment>
                      <DirectConnectAndCreditToast />
                      <Container client={client} />
                    </React.Fragment>
                  ),
                  exact: true,
                },
                { id: 1, path: "/styleguide", render: () => <StyleGuide /> },
                {
                  id: 2,
                  path: "/:type(company|enterprise|partner)/login",
                  render: () => <Login />,
                },
                {
                  id: 3,
                  path: "/:type(company|enterprise|partner)/forgot-password",
                  render: () => <ForgotPassword />,
                },
                {
                  id: 4,
                  path: "/:type(company|enterprise|partner)/reset-password/:token",
                  render: () => <ResetPassword />,
                },
                {
                  id: 5,
                  path: "/ghost-users/login-as/:token",
                  render: () => <GhostUserLogin />,
                },
                {
                  id: 6,
                  path: "/partner-integration/service-fusion/login",
                  render: () => <ServiceFusionLogin />,
                },
                {
                  id: 7,
                  path: "/partner",
                  render: () => <Partner />,
                },
              ]}
            />
            {isAuthenticated && (
              <>
                <Route
                  path="/:type(company|enterprise|partner)/update-expired-password"
                  component={ResetPassword}
                />

                <Route path="/enterprise" component={Enterprise} />
                {/* <Route path="/partner" component={Partner} /> */}
              </>
            )}
          </BrowserRouter>
        </ApolloProvider>
      </Sentry.ErrorBoundary>
    </BlockUi>
  );
};

export default App;
